import React from 'react';
import styles from './Brands.module.scss';

export default function Brands() {
  return (
    <div className={styles.page}>
      <h1>Brands</h1>
    </div>
  );
}
